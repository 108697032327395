import React, { useState, useEffect } from 'react';
import { FaEye } from 'react-icons/fa';
import { CircularProgress } from '@mui/material';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { useAlert } from '../../../alert/useAlert';
import { useNavigate } from 'react-router-dom';

const FindUser = () => {
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token");
    const navigate = useNavigate()
    const showMessage = useAlert()

    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/plus`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();
                setUsers(json.data);
            } catch (error) {
              showMessage("Failed to fetch data", 'error');
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);

    const filteredUsers = users.filter(email =>
        email.toLowerCase().includes(search.toLowerCase())
    );
    const OnClick = (params)=>{
        navigate("/admin/user-details",{state:params})
    }

    return (
        <div className="p-6 max-w-3xl mx-auto">
            {/* Search Bar */}
            <div className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md mb-6">
                <input
                    type="text"
                    placeholder="Search for a user..."
                    className="w-full p-3 border-none rounded-l-lg focus:outline-none text-gray-700"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <FaMagnifyingGlass className="w-6 h-6 text-gray-500 p-3 cursor-pointer" />
            </div>
            {/* Loading State */}
            {loading && (
                <div className="flex justify-center items-center h-40">
                  <CircularProgress/>
                </div>
            )}
            {/* User List */}
            {!loading && (
                <div className="bg-white border border-gray-300 rounded-lg shadow-md">
                    <h3 className='p-3 m-2 rounded-lg bg-white shadow-lg w-fit items-center'>
                    {users.length}
                    </h3>
                    <ul className="divide-y divide-gray-200">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((email, index) => (
                            <button onClick={()=>OnClick(email)}>
                                <li key={index} className="flex items-center justify-between p-4 hover:bg-gray-100">
                                    <span className="text-lg font-medium text-gray-900">{email}</span>
                                    <FaEye className="w-6 h-6 text-blue-500 cursor-pointer hover:text-blue-700" />
                                </li>
                                </button>
                            ))
                        ) : (
                            <li className="p-4 text-center text-gray-500">No users found</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FindUser;
