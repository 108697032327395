import React from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";

export const Help = () => {
  const phoneNumber = '+2349061229992'; // Replace with the actual phone number in international format
  const message = 'Hello, I would like to inquire about your services.';

  // WhatsApp URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center">
        <h1 className="text-2xl font-bold text-darkblue mb-6">Contact Us</h1>

        <div className="flex flex-col items-center space-y-4 mb-6">
          <button
            onClick={() => window.open('mailto:samuelyyyy257@gmail.com')}
            className="bg-slate-800 text-white p-4 rounded-full flex items-center justify-center shadow-lg hover:bg-slate-700 transition duration-300 ease-in-out"
          >
            <FaMailBulk className="text-2xl" />
          </button>
          <button
            onClick={() => window.open(whatsappUrl, '_blank')}
            className="bg-green-600 text-white p-4 rounded-full flex items-center justify-center shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
          >
            <FaWhatsapp className="text-2xl" />
          </button>
        </div>

        <div className="bg-red-100 text-red-600 p-4 rounded-lg border border-red-300 mb-4">
          <p className="text-sm">
            If you are having transaction problems, please message us via WhatsApp with your receipt (Please send other queries to mail).
          </p>
        </div>
      </div>
    </div>
  );
};
