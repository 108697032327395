import React from "react";
import { FaShoppingBag, FaPhone, FaWifi, FaBook, FaSalesforce, FaLightbulb, FaBars } from "react-icons/fa";
import { FaTv } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";
import { Scollling } from "./scrollservices";


export const Allserv = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  

  const HandleAll = () => {
    showAlert("All other utilities not available on the web", "success");
  };
  return (

    <>
    

    <div className="p-4 grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
      {/* Grid Items */}
    
      <button 
        onClick={() => window.open('https://wa.me/+2349061229992', '_blank')}
        className="flex flex-col items-center bg-gray-50 w-fit h-fit text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-red-300 p-2 rounded-full mb-2">
          <FaSalesforce className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-gray-950 text-center">Sell</p>
      </button>

      <button
        onClick={() => navigate('airtime')}
        className="flex flex-col items-center w-fit h-fit bg-gray-50 text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-blue-200 p-2 rounded-full mb-2">
          <FaPhone className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-grey-950 text-center">Airtime</p>
      </button>

      <button
        onClick={() => navigate("data")}
        className="flex flex-col items-center bg-gray-50 h-fit w-fit text-gray-800 p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-teal-200 p-2 rounded-full mb-2">
          <FaWifi className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-gray-950 text-center">Data</p>
      </button>
        <button
        onClick={HandleAll}
        className="flex flex-col w-fit h-fit items-center bg-gray-50 text-white p-3 rounded-lg shadow-sm focus:outline-none"
      >
        <div className="bg-blue-500 p-2 rounded-full mb-2">
          <FaBars className="text-sm" />
        </div>
        <p className="font-bold text-xs text-center text-gray-950">More</p>
      </button>
    </div>
    <div className="text-sm bg-gray-500 text-white p-3 mb-3 rounded-lg text-center">
      <p>
         December Promo !!!!
            Be a top user and share N50K
      </p>
        </div>
    <div className="text-sm bg-gray-500 text-white p-3 rounded-lg text-center">
            Click here to join our Refer programme
            Get instant N500 when you refer and get 10% of each referer deposit and more to come.
           <button className="text-sm p-3 m-1 bg-blue-500">
            Click Here
           </button>
        </div>
       
<Scollling />
    </>
  );
};
