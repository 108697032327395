import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Select, CircularProgress} from "@mui/material";
import { Modal } from "@mui/material";
import { useAlert } from "../../component/alert/useAlert";
import { BottomSheet } from "./GeneralOutput";
import { FaNairaSign } from "react-icons/fa6";

const generateRequestId = () => {
    const lagosTimezoneOffset = 60; // Lagos timezone is GMT+1, so the offset is 60 minutes
    const now = new Date();
    const lagosTime = new Date(now.getTime() + lagosTimezoneOffset * 60000);

    const year = lagosTime.getUTCFullYear().toString();
    const month = (lagosTime.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = lagosTime.getUTCDate().toString().padStart(2, '0');
    const hour = lagosTime.getUTCHours().toString().padStart(2, '0');
    const minute = lagosTime.getUTCMinutes().toString().padStart(2, '0');

    const requestIdPrefix = year + month + day + hour + minute;
    const additionalCharacters = 'ad8ef08acd8fc0f'; // Example additional characters
    const requestId = requestIdPrefix + additionalCharacters.slice(0, Math.max(0, 12 - requestIdPrefix.length));

    return requestId;
};

const generateRandomString = (length) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
};

const encode = (credentials) => {
    return btoa(credentials); // Base64 encoding
};

const ModalComponent = ({ open, amount, onClose, data, provider, phone, showMessage, user_,setLoading, loading }) => {
    const [userPin, setUserPin] = useState('');

    const handleButtonClick = (digit) => {
        if (userPin.length < 4) {
          setUserPin((prev) => prev + digit);
        }
      };
    
      const handleRemoveLast = () => {
        setUserPin((prev) => prev.slice(0, -1));
      };
    

    const handleSubmit = async () => {
        if (userPin.length !== 4) {
            showMessage("Your PIN must be 4 digits", "error");
            return;
        }
        if (userPin.trim() === data.profile.transaction_pin && amount <= data.profile.account_balance) {
           console.log(phone)
            const requestId = generateRequestId();
            const randomString = generateRandomString(12);
            const y_id = requestId + randomString;
            const email = "samuelyyyy257@gmail.com";
            const password = "antydamilove1";
            const credentials = `${email}:${password}`;
            const encodedCredentials = encode(credentials);
            const url = `https://vtpass.com/api/pay?serviceID=${provider}&request_id=${y_id}&amount=${amount}&phone=${phone}`;
            try {
                setLoading(true);
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Authorization": `Basic ${encodedCredentials}`,
                    },
                });
                const data = await response.json();
                    await fetch('https://www.softnixx.com/api/createR/', {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'Authorization': `Token ${localStorage.getItem('token')}`,
                        },
                        body: JSON.stringify({
                            amount: data.amount,
                            status: data.content.transactions.status,
                            reference: data.content.transactionId,
                            request_id: y_id,
                            user_id:user_,
                            name: 'Utility',
                        })
                    });
                    showMessage("Payment Successful", 'success');
                
            } catch (error) {
                showMessage("An error occurred", "error");
            } finally {
                setLoading(false);
                onClose();
            }
        } else {
            showMessage("Error: Incorrect PIN or insufficient balance", "error");
        }
    };

    return (
        <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: semi-transparent background
      }}>
        <div style={{
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '8px',
          maxWidth: '400px',
          width: '100%', // Make it responsive
        }}>
          <h3 className="text-blue-950 p-3 m-3 text-2xl">Enter your 4-Digit Pin</h3>
          <div className="text-center" style={{ fontSize: '24px', margin: '20px 0' }}>
            {userPin.replace(/./g, '*')} {/* Hide digits */}
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
              <button button className="text-xl p-2 m-2 shadow-lg bg-white" variant="contained" key={digit} onClick={() => handleButtonClick(digit)}>
                {digit}
              </button>
            ))}
            <button button className="text-xl p-2 m-2 shadow-lg bg-white" variant="contained" onClick={() => handleButtonClick(0)}>
              0
            </button>
            <button button className="text-xl p-2 m-2 shadow-lg bg-white" variant="contained" onClick={handleRemoveLast}>
              clear
            </button>
          </div>
          <p><strong>Amount:</strong> {amount}</p>
          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <button button className="text-xl p-2 m-2 shadow-lg bg-white" onClick={onClose}>Cancel</button>
                <button button className="text-xl p-2 m-2 shadow-lg bg-white" onClick={handleSubmit} disabled={userPin.length !== 4}>
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  
    );
};

export default ModalComponent;



export const Airtime = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState("");
    const [provider, setProvider] = useState("");
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const showMessage = useAlert();
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('user');

    const dataCard = [
        { label: 'MTN', value: 'mtn' },
        { label: 'GLO', value: 'glo' },
        { label: 'ETISALAT', value: 'etisalat' },
        { label: 'AIRTEL', value: 'airtel' }
    ];

    useEffect(() => {
        const getDetails = async () => {
            if (!token || !username) {
                showMessage('Something missing..', 'error');
                return;
            }
            setLoading(true);
            const itemUrl = `https://www.softnixx.com/api/updateD/${username}/`;
            try {
                const response = await fetch(itemUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Token ${token}`,
                    },
                });

                if (!response.ok) {
                    showMessage('Check your network connections!', 'error');
                    return;
                }
                const json = await response.json();
                setData(json);
            } catch (error) {
                showMessage('Not allowed to view this page...', 'error');
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token, username, showMessage]);

    const handleSubmit = () => {
        if (data?.profile?.account_balance < amount) {
            showMessage('Please fund your wallet', 'error');
            return;
        }
        if (!phone || !amount || !provider) {
            showMessage('Please fill all fields', 'error');
            return;
        }
        setOpen(true);
    };

    const handleModalClose = () => setOpen(false);
    const handleBottomSheetToggle = () => setIsOpen(!isOpen);

    const MoneyConvert = (num) => {
        return num?.toLocaleString();
    };

    return (
        <div className="container mx-auto p-5 bg-white shadow-lg rounded-lg mb-20">
            <h1 className="text-2xl font-semibold text-center mb-4 text-blue-950">Buy Airtime in a Minute</h1>
            <p className="text-red-600 p-2 text-justify">
                Please always cross-check your number as an invalid number will lead to a failed request.
            </p>
            <div className="bg-darkblue text-white p-6 rounded-xl shadow-md mb-6">
                <div className="text-sm font-semibold">
                    <h3 className="text-xl text-gray-600">Balance</h3>
                    <p className="text-2xl text-gray-800 flex items-center">
                        <FaNairaSign />
                        <span>{data?.profile?.account_balance?.toLocaleString() || 'please wait...'}</span>
                    </p>
                </div>
                <TextField
                    placeholder="Your phone number"
                    variant="outlined"
                    type='tel'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full p-4 text-2xl bg-gray-100 rounded-md mt-4"
                />
            </div>

            <div className="mb-6">
                <label htmlFor="provider-select" className="block text-sm text-center font-medium text-gray-700 mb-2">
                    Select Provider
                </label>
                <Select
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                    variant="outlined"
                    className="w-full mb-4"
                >
                    {dataCard.map((card) => (
                        <MenuItem key={card.value} value={card.value}>
                            {card.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <div className="mb-6">
                <label htmlFor="plan-select" className="block text-center font-medium text-2xl text-gray-700 mb-2">
                    Select Amount
                </label>
                <div className="flex flex-wrap justify-center gap-2 mb-4">
                    {[100, 200, 300, 400, 500, 1000, 2000, 5000].map((amt) => (
                        <button
                            key={amt}
                            variant={amount === amt.toString() ? "contained" : "outlined"}
                            onClick={() => setAmount(amt.toString())}
                            className="text-2xl shadow-lg w-20 p-2 bg-white"
                        >
                            &#8358;{amt.toLocaleString()}
                        </button>
                    ))}
                </div>

                <div className="flex items-center justify-between mb-4">
                    <TextField
                        className="flex-grow p-3"
                        type="number"
                        placeholder="Enter amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="ml-2"
                    >
                        {loading ? <CircularProgress size={24} /> : 'Submit (Pay)'}
                    </button>
                </div>
            </div>

            <ModalComponent
                open={open}
                amount={amount}
                onClose={handleModalClose}
                loading={loading}
                data={data}
                user_={data.id}
                provider={provider}
                phone={phone}
                showMessage={showMessage}
                setLoading={() => setLoading(!loading)}
                isOpen={handleBottomSheetToggle}
            />
            <BottomSheet
                phone={phone}
                price={amount}
                isOpen={isOpen}
            />
        </div>
    );
};
