import React from "react";
import { FaShoppingBag, FaBitcoin, FaPhone, FaWifi, FaBook, FaSalesforce, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { useAlert } from "../../component/alert/useAlert";
import { CircularProgress } from "@mui/material";
import Logo from '../../../src/comp.jpg'
import { FaMoneyBillTransfer, FaNairaSign } from "react-icons/fa6";
import { CgClose, CgSearchFound } from "react-icons/cg";




export const Scollling = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const showMessage = useAlert();
const [loading, setLoading] = useState(false);
const [data, setData] = useState([]);

const fetchNotifications = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = "https://www.softnixx.com/api/generate_receipt/";

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Token ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
    } catch (error) {
        showMessage("Something went wrong!...", "error");
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
    fetchNotifications();
}, []);



const Timeset = (data) => {
    const date = new Date(data);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};



  return (
    <>

<center>
        <div className="container  ml-3 mr-3 p-4 mb-32 bg-white shadow-lg rounded-lg">
            <h5 className="text-gray-600 font-mono text-sm">Recent Activities </h5>
          
            {loading ? (
                <div className="flex justify-center items-center">
                    <CircularProgress />
                </div>
            ) : data.length === 0 ? (
                <div className="flex flex-col items-center space-y-4">
                    <p className="text-gray-600">You have no notifications at the moment.</p>
                </div>
            ) : (
            <div className="p-1 m-1">
             {data.splice(0,5).map((item,index)=>(
              <div className={`${index%2 ==0?"bg-white":"bg-gray-100"} rounded-sm p-1 m-1 flex flex-row items-center justify-between`}>

<div>
<h4> 

  <FaMoneyBillTransfer color="red"  size={25} className="p-2 w-fit bg-gray-400 rounded-lg" />  
</h4>
</div>       
<div>
  <h3 className="text-sm">

  {item?.name ==="gift"?(item?.name==="gift"?"Airtime purchased":"Data and Utilities"):(item?.name)}
  </h3>
  <h6 style={
    {
        fontSize:10
    }
  } className="text-left text-gray-400">{Timeset(item.createdAt)}</h6>
  </div>
  <div className="flex flex-row items-center bg-red-400 rounded-lg p-1 ">
                <FaNairaSign size={10} className=" text-white" /> <h6 className="text-sm  text-white">{item.amount.toLocaleString()}</h6> 
</div>
              </div>
             ))}
              </div>
            )}
            <button className="text-sm p-2 bg-blue-800 text-white rounded-lg" onClick={()=>navigate("notification")}>
              View All
            </button>
        </div>

        <div>

            </div>
    
        </center>
    </>
  );
};


