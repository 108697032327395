import { Card } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { FaNairaSign } from "react-icons/fa6";
import { useAlert } from "./alert/useAlert";

const BenefitOverlay = ({ navigation, route }) => {
   const showMessage = useAlert(); // Using the custom hook
   const [data, setData] = useState(null);
   const [loadingDetails, setLoadingDetails] = useState(true);
   const [loadingGift, setLoadingGift] = useState(true);
   const [gift, setGift] = useState([]);
   const [amount, setAmount] = useState("");
   const [buttonLoading, setButtonLoading] = useState(false);

   const token = localStorage.getItem("token");
   const username = localStorage.getItem("user");

   const convertPoint = async () => {
      try {
         setButtonLoading(true);
         const response = await fetch("https://www.softnixx.com/api/convert", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
               point: parseFloat(amount.trim()),
               id: data?.id
            })
         });
         
         const result = await response.json();
         if (!response.ok) {
            showMessage(result.error, "error");
            return;
         }
         showMessage(result.success, "success");
      } catch (error) {
         console.error(error);
         showMessage("An error occurred", "error");
      } finally {
         setButtonLoading(false);
      }
   };


   const handleGiftClaim = async (id, amount) => {
      try {
         setButtonLoading(true);
         const response = await fetch("https://www.softnixx.com/api/get_gift/", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
               amount: amount,
               id: id,
               user_id: data?.id
            })
         });

         const result = await response.json();
         if (!response.ok) {
            showMessage("Something went wrong", "error");
         } else {
            showMessage(result.message, "success");
         }
      } catch (error) {
         console.error(error);
         showMessage("An error occurred", "error");
      } finally {
         setButtonLoading(false);
      }
   };

   const getDetails = async () => {
      if (!token || !username) {
         showMessage("Something is missing...", "error");
         return;
      }
      setLoadingDetails(true);
      const itemUrL = `https://www.softnixx.com/api/updateD/${username}/`;
      try {
         const response = await fetch(itemUrL, {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
         });
         if (!response.ok) {
            throw new Error("Network response was not ok.");
         }
         const json = await response.json();
         setData(json);
      } catch (error) {
         showMessage(`Error: ${error.message}`, "error");
      } finally {
         setLoadingDetails(false);
      }
   };

   const getGift = async () => {
      setLoadingGift(true);
      const itemUrL = `https://www.softnixx.com/api/giftall`;
      try {
         const response = await fetch(itemUrL, {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
         });
         const json = await response.json();
         setGift(json);
      } catch (error) {
         showMessage(`Error: ${error.message}`, "error");
      } finally {
         setLoadingGift(false);
      }
   };

   useEffect(() => {
      getDetails();
      getGift();
   }, []);

   return (
      <div className="pb-4 mb-28">
         <div className="text-2xl text-center p-2 m-2 shadow-md bg-gray-500 text-white rounded-lg">
            <h4 className="text-sm">Your rewards have arrived! Keep using Softnixx for more benefits.</h4>
            <p className="text-sm">Leave your point and be eligible for offers to come.</p>
         </div>
         <div>
            <div className="flex flex-row">
               <div className="text-2xl p-4 rounded-lg m-3 bg-gray-100 w-fit">
                  <h3>{data?.profile?.point_bonus || 0} P</h3>
                  <p>{data?.profile?.point_bonus >= 10 ? "Eligible" : "Not eligible"}</p>
               </div>
               <div className="text-2xl p-4 rounded-lg m-3 bg-gray-100 w-fit">
                  <h3>Balance: {data?.profile?.account_balance?.toLocaleString() || "0"}</h3>
               </div>
            </div>

            {(loadingDetails || loadingGift) ? (
               <FaSpinner className="animate-spin text-2xl" />
            ) : (
               <div>
                  <div className="flex flex-col">
                     <label className="text-1xl text-gray-700 p-1 m-2">
                        Input 10 or more points to continue
                     </label>
                     <input
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="border-spacing-1 bg-slate-50 p-4 m-3 text-2xl"
                        placeholder="How many points do you want to convert? (10 points or more)"
                     />
                  </div>
                  {buttonLoading ? (
                        <FaSpinner className="animate-spin" />
                     ) : (
                        <button onClick={convertPoint} className="text-sm text-white bg-blue-700 pl-5 pr-5 pt-2 pb-2 rounded-lg m-4">
                        Submit
                     </button>
                     )}
               </div>
            )}
         </div>

         <div>
            <h3 className="text-gray-600 text-center text-2xl">Gifts available to claim</h3>
            <div className="flex flex-row overflow-x-scroll items-center justify-between">
               {gift.map((item, index) => (
                  <div key={index} className="bg-white shadow-lg rounded-lg p-3 m-3">
                     <h4 className="text-2xl text-black p-2 m-2 text-center">
                        {item.product}
                     </h4>
                     <h3 className="flex flex-row items-center text-2xl">
                        <FaNairaSign /> {item.price.toLocaleString()}
                     </h3>
                     <p>Items left: {item.neede_uer}</p>
                     {buttonLoading ? (
                        <FaSpinner className="animate-spin" />
                     ) : (
                        <button
                           onClick={() => handleGiftClaim(item.id, item.price)}
                           disabled={item.neede_uer === 0}
                           className={`${item.neede_uer===0?"bg-gray-300":"bg-blue-950"} text-white  p-2 `}
                        >
                           {item.neede_uer === 0 ? "Finished" : "Claim now"}
                        </button>
                     )}
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default BenefitOverlay;
