import React from 'react';

export const Project = () => {
  return (
    <div className="py-12 pt-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        {/* About Our AI Section */}
        <section className="mb-12 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">About Our AI (Sonita)</h1>
          <p className="text-gray-700 leading-relaxed">
            Our AI is built on JavaScript and processes your input to analyze and provide responses. It learns from user interactions and improves over time. 
            This tool was designed to enhance the usability of Softnixx products based on user interactions and the procedures involved.
          </p>
        </section>

        {/* Buying Data Section */}
        <section className="mb-12 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Buying Data</h1>
          <p className="text-gray-700 mb-4">
            To fetch details, send a POST request to the following URL with your token:
          </p>
          <div className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800 mb-4">
            <code className="block">https://softnixx.com/api/purchase</code>
            <code className="block mt-2">
              {`{
  "token": "your_token_here",
  "network": "network_name",
  "amount": "amount_value",
  "type": "transaction_type",
  "pin": "your_pin"
}`}
            </code>
          </div>
          <p className="text-gray-700 mt-4">
            Expected response:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800">
            {`{
  "username": "samuel",
  "status": "delivered|fail|pending",
  "amount": "200 3GB",
  "phone": "phone_number",
  "reference": "soft:92ndn338883"
}`}
          </pre>
          <p className="text-gray-700 mt-4">
            Error response:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800">
            {`{
  "error": "No access",
  "status": "404"
}`}
          </pre>
        </section>

        {/* Buying Airtime Section */}
        <section className="mb-12 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Buying Airtime</h1>
          <p className="text-gray-700 mb-4">
            To buy airtime, send a POST request to the following URL with your token:
          </p>
          <div className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800 mb-4">
            <code className="block">https://softnixx.com/api/purchase</code>
            <code className="block mt-2">
              {`{
  "token": "your_token_here",
  "network": "network_name",
  "amount": "amount_value",
  "phone": "phone_number",
  "pin": "your_pin"
}`}
            </code>
          </div>
          <p className="text-gray-700 mt-4">
            Expected response:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800">
            {`{
  "phone": "phone_number",
  "status": "delivered|fail",
  "amount": "amount_value",
  "reference": "soft:92ndn338883"
}`}
          </pre>
          <p className="text-gray-700 mt-4">
            Error response:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800">
            {`{
  "error": "No access",
  "status": "404"
}`}
          </pre>
        </section>

        {/* Fetching Details Section */}
        <section className="mb-12 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Fetching Details</h1>
          <p className="text-gray-700 mb-4">
            To fetch details, send a GET request to the following URL with your token:
          </p>
          <div className="bg-gray-950 p-4 rounded-md text-sm font-mono text-red-400 mb-4">
            <code className="block">https://softnixx.com/api/updateD/</code>
          </div>
          <p className="text-gray-700 mt-4">
            Expected response:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800">
            {`{
  "username": "samuel",
  "account_balance": "98.00"
}`}
          </pre>
          <p className="text-gray-700 mt-4">
            Error response:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md text-sm font-mono text-gray-800">
            {`{
  "error": "No access",
  "status": "404"
}`}
          </pre>
        </section>
      </div>

      <div className="text-center text-gray-700 mt-12">
        <p className="text-sm font-semibold">API is expected to be active soon! all response will be null</p>
      </div>
    </div>
  );
};
