import React, { useState } from "react";
import { useAlert } from "../component/alert/useAlert";

export const DeleteApp = () => {
    const showMessage = useAlert();
    const [username, setUsername] = useState(""); // Fixed typo in the state variable name
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem("token");

    const deleteAccount = async () => { // Renamed function for clarity
        if (!token) {
            showMessage("You need to log in", "error");
            return; // Prevent further execution if not logged in
        }

        setLoading(true); // Set loading state

        const createNewUrl = 'https://www.softnixx.com/api/delete';
        try {
            const response = await fetch(createNewUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({
                    username: username,
                    reason: reason, // Include reason in the request
                }),
            });

            const data = await response.json();
            if (response.ok) {
                showMessage("Account deleted successfully", "success");
            } else {
                showMessage(data.message || "An error occurred", "error");
            }
        } catch (error) {
            showMessage("An error occurred", "error");
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="mt-32 p-4 flex flex-col items-center">
            <h2 className="text-2xl text-blue-950 text-center w-full md:w-1/2">
                Please note that deleting your account means you will lose access to all your funds. We advise you to withdraw all your funds before proceeding with account deletion.
            </h2>
            <div className="flex flex-col items-center mt-4 w-full md:w-1/2">
                <input 
                    type="text" 
                    placeholder="Your username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    className="mb-4 p-3 w-full border border-gray-100 rounded"
                />
                <input 
                    type="text" // Fixed the type to "text"
                    placeholder="Reason (this is optional)" 
                    value={reason} 
                    onChange={(e) => setReason(e.target.value)} 
                    className="mb-4 p-3 w-full border border-gray-100 rounded"
                />
                <button 
                    onClick={deleteAccount} 
                    className={`p-6 ${loading ? "bg-gray-400" : "bg-red-500"} text-white rounded w-full hover:bg-red-600 transition duration-200`}
                    disabled={loading} // Disable button when loading
                >
                    {loading ? "Loading..." : "Submit"}
                </button>
            </div>
            <h2 className="mt-6 text-lg text-center w-full md:w-1/2 text-gray-700">
                You can also let us know the reason for your account action
            </h2>
            <button 
                onClick={() => window.open("mailto:softtellex@gmail.com")} 
                className="mt-4 p-3 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
            >
                Click here
            </button>
        </div>
    );
};
