import React, { useState } from 'react';
import { FaPowerOff } from 'react-icons/fa';
import { AiOutlineSend } from 'react-icons/ai';
import './justomize.css'; // Ensure your CSS file includes styles for the chat page
import { FaWhatsapp } from 'react-icons/fa6';

// Popup Component
export const PopUp = ({ useTrue, onClose }) => {
  return (
    useTrue ? (
      <div className='popup-container fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50'>
        <div className='popup-content bg-white p-5 rounded-lg shadow-lg'>
          <h2 className='text-lg font-semibold mb-4'>
            I am Sonita. I want permission to use your data to train me.
          </h2>
          <div className='flex justify-between'>
            <button onClick={() => onClose()} className='p-2 bg-blue-600 text-white rounded-lg'>
              Close
            </button>
            <button onClick={() => onClose()} className='p-2 bg-red-600 text-white rounded-lg'>
              No
            </button>
          </div>
        </div>
      </div>
    ) : null
  );
};


// Chat Interface Component
export const Hommie = () => {
  const [message, setMessage] = useState('');
  const [useTrue, setTrue] = useState(true); // Controls popup visibility
  const [messages, setMessages] = useState([
    { id: 1, text: "Hello, how can I help you today?" },
    { id: 2, text: "I am just learning i am not perfect." }
  ]);
  const [loading, setLoading] = useState(false); // Controls loading state

  // Contact link for further assistance
  const contactLink = "https://wa.me/+2349061229992";
  const Button =()=>{
    return (
        <button onClick={()=>window.ope}>
            
        </button>
    )
  }

  const response = [
    "I'm here to help with any issues you might have.",
    "Please provide more details so I can assist you.",
    "For further assistance, you might want to check our FAQ section.",
    "I am Sonita, built on 29th August 2024. I am Softnixx's assistant.",
    "How are you? How can I help you today?",
    "To deposit into your account, you will be redirected to the payment page. Input the amount to deposit and click on 'Transfer Method'. Please wait for the server to confirm your request.",
    "To create an account, kindly use this page: https://softnixx.com/register. An email will be sent to you, which you can use to verify your account. Note: Check your spam folder if the email is not found.",
    "To log in, enter your username and password. Please ensure the case sensitivity of your credentials.",
    "Terms and conditions can be found here: https://softnixx.com/terms",
    "About us: https://softnixx.com/about",
    "For more assistance, please call 08130423221 or send us an email at softtellex@gmail.com",
    "You can transfer to your friend's wallet via username or SN number, but this is only available on the app. It’s a free transfer and your friends will receive it within a minute.",
    "You can buy any Intulies product or transfer to your friends using the app or website. The app is more stable than the website."
  ];

  const handleOpenWhatsApp = () => {
    window.open('https://wa.me/+2349061229992', '_blank');
  };
  const handleSendMessage = () => {
    if (message.trim()) {
      // Add the user's message to the chat
      setMessages([...messages, { id: messages.length + 1, text: message }]);
      setMessage('');

      // Simulate AI processing time
      setLoading(true);
      setTimeout(() => {
        let aiResponse = "I didn't understand that.";
        const lowerMessage = message.toLowerCase();

        if (lowerMessage.includes("help")) {
          aiResponse = response[2];
        } else if (lowerMessage.includes("login")) {
          aiResponse = response[7];
        } else if (lowerMessage.includes("what i can do") || lowerMessage.includes("how to transfer to friends") || lowerMessage.includes("what is inline in my app")) {
          aiResponse = response[10];
        } else if (lowerMessage.includes("utilities") || lowerMessage.includes("buy") || lowerMessage.includes("purchase")) {
          aiResponse = response[12];
        } else if (lowerMessage.includes("about")) {
          aiResponse = response[9];
        } else if (lowerMessage.includes("register") || lowerMessage.includes("money") || lowerMessage.includes("add money")) {
          aiResponse = response[6];
        } else if (lowerMessage.includes("deposit")) {
          aiResponse = response[5];
        } else if (lowerMessage.includes("my account")) {
          aiResponse = response[1];
        } else if (lowerMessage.includes("about you") || lowerMessage.includes("tell me about you") || lowerMessage.includes("about")) {
          aiResponse = response[3];
        } else if (lowerMessage.includes("hi") || lowerMessage.includes("hey") || lowerMessage.includes("what's up") || lowerMessage.includes("how are you")) {
          aiResponse = response[4];
        } else {
         aiResponse ='talk to our agents'
        }

        setMessages(prevMessages => [
          ...prevMessages,
          { id: prevMessages.length + 1, text: aiResponse }
        ]);
        setLoading(false);
      }, 5000); // 5-second delay
    }
  };


  return (
    <div className="chat-container font-serif flex flex-col h-screen bg-gray-100">
      {/* Header */}
      <div className="header flex justify-between items-center p-4 bg-blue-950 text-white">
        <div>
          <h3 className="text-xl font-semibold">Welcome, I am Sonita</h3>
        </div>
        <div className="logout cursor-pointer text-2xl" title="Logout">
          <FaPowerOff />
        </div>
      </div>

      <button onClick={handleOpenWhatsApp} className="fixed top-1/2">
        <FaWhatsapp className='text-green-950 text-9xl'  />
      </button>

      {/* Chat Area */}
      <div className="chat-area flex-grow p-4 overflow-y-scroll bg-gray-50 border-t border-gray-300">
        {messages.map(msg => (
          <div key={msg.id} className="message p-3 mb-3 bg-white rounded-lg shadow-md">
            {/* Render links as HTML */}
            <div dangerouslySetInnerHTML={{ __html: msg.text }} />
          </div>
        ))}
        {loading && (
          <div className="loading-indicator flex items-center justify-center p-3 bg-gray-200 rounded-lg">
            <div className="spinner-border animate-spin border-4 border-blue-600 border-t-transparent rounded-full w-8 h-8"></div>
            <span className="ml-2">I am typing...</span>
          </div>
        )}
      </div>

      {/* Message Input */}
      <div className="message-input flex items-center p-4 bg-gray-200 border-t border-gray-300">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="flex-grow p-2 rounded-lg border border-gray-300"
        />
        <button onClick={handleSendMessage} className="ml-2 p-2 bg-blue-600 text-white rounded-lg flex items-center">
          <AiOutlineSend className="text-xl" />
        </button>
      </div>

      {/* Popup */}
      <PopUp useTrue={useTrue} onClose={() => setTrue(false)} />
    </div>
  );
};

