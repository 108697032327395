import React from "react";
import { RegisterScreen } from "./Register";



const RegisterPage =()=>{

return (
    <RegisterScreen />
)

} 

export default RegisterPage