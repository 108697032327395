import React, { useState } from 'react';
import { Modal, Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ModalComponent = ({ open, onClose }) => {
    const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  const formatNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '' : number.toLocaleString();
  };

  const handleSubmit = () => {
    navigate("/pay",{state:{"value":inputValue}})
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="Enter Amount to deposit"
            type="number"
            fullWidth
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
          />
          <text>deposit value: N{formatNumber(inputValue)}</text>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{inputValue?"Submit":null}</Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default ModalComponent;
