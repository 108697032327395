import React from "react";
import { Button } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const BottomSheet = ({ isOpen, plan, phone, price }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-50">
            <div className="bg-white w-full sm:w-2/3 lg:w-1/2 xl:w-1/3 h-1/2 rounded-t-lg shadow-lg p-4">
                <div className="text-center mb-4">
                    <FaCheckCircle 
                        className="mx-auto mb-2 bg-green-500 text-white rounded-full text-4xl"
                    />
                    <h2 className="text-xl font-bold text-blue-950">Download the app for more functions</h2>
                    <p className="mt-2 text-gray-700">
                        Your purchase Was successful 
                    </p>
                </div>
                <div className="flex justify-center mb-4">
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/home")}
                    >
                        Finished
                    </Button>
                </div>
                <p className="text-center text-gray-500">Be part of the programm </p>
                <h6 className="text-center text-gray-500">
                Register as an api agent send a message..
                </h6>
            </div>
        </div>
    );
};
