import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";

export const Receipt = () => {
  const location = useLocation();
  const id = location.state?.id;

  const [data, setData] = useState([]);

  return (
    <div className="container mx-auto p-6 bg-white text-darkblue shadow-lg rounded-lg max-w-lg">
      <div className="flex flex-col items-center justify-center text-center">
        <FaExclamationTriangle className="text-4xl text-yellow-500 mb-4" />
        <h2 className="text-2xl font-bold mb-4">Receipt Not Available</h2>
        <p className="text-lg text-gray-600 mb-6">
          Unfortunately, the receipt is not available on the web at the moment.
          <br />
          If you need assistance or have any questions, please reach out to us.
        </p>
        <button
          onClick={() => window.location.href = 'mailto:support@softnixx.com'}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Contact Support
        </button>
      </div>
    </div>
  );
};
