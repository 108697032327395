import React, { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";
import { TextField, Button, CircularProgress } from "@mui/material";
import { FaCopy } from "react-icons/fa";

export const Paystack = () => {
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [data, setData] = useState({});
  const showMessage = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("user");

  useEffect(() => {
    const getDetails = async () => {
      if (!token || !username) {
        showMessage("Something missing..", "error");
        return;
      }
      setLoading(true);
      const itemUrl = `https://www.softnixx.com/api/updateD/${username}/`;
      try {
        const response = await fetch(itemUrl, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (!response.ok) {
          showMessage("Check your network connections!", "error");
          return;
        }
        const json = await response.json();
        setData(json);
        const authorization = "Bearer sk_live_ea796eb8e2daee044b8143ccc52c8c5f155da9d2";
        const url = "https://api.paystack.co/dedicated_account";
        const dataPaser = {
          customer: json?.profile?.paystack_id,
          phone: json?.profile?.phone_number,
          email: json?.email,
          first_name: json?.last_name,
          last_name: json?.first_name,
          preferred_bank: "titan-paystack",
        };

        const response2 = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataPaser),
        });

        if (!response2.ok) {
          showMessage("Something went wrong", "error");
          return;
        }
        const data3 = await response2.json();
        setCustomerData(data3.data);
      } catch (error) {
        showMessage("Not allowed to view this page...", "error");
      } finally {
        setLoading(false);
      }
    };

    getDetails();
  }, [token, username, showMessage]);

  const amount = location.state?.value || 0;
  const publicKey = "pk_live_46758e921078ab1b44d7e515c862b337ad6968d0";
  const email = data?.email;
  const phone = data?.profile?.phone_number;

  const componentProps = {
    email,
    amount: amount * 100,
    metadata: {
      phone,
    },
    publicKey,
    text: `Pay Now NGN ${amount}`,
    onSuccess: () => navigate("/home"),
    onClose: () => showMessage("Payment closed, you can try again.", "error"),
  };

  const copyToClipboard = (text) => {
    if (text) {
      navigator.clipboard.writeText(text)
        .then(() => showMessage("Text copied to clipboard", "success"))
        .catch((error) => showMessage(`Failed to copy text: ${error.message}`, "error"));
    } else {
      showMessage("No text to copy", "error");
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white text-darkblue shadow-lg rounded-lg max-w-md">
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4 text-blue-700">Deposit Details</h1>
          <div className="mb-4 p-4 bg-blue-100 text-blue-700 rounded-lg shadow-md flex flex-col items-start">
            <h6 className="text-base font-semibold mb-2">Transfer into this account anytime. Your balance will automatically reflect.</h6>
            <p className="flex items-center mb-2">
              Your personal Account Number: <span className="ml-2">{customerData?.account_number}</span>
              <FaCopy onClick={() => copyToClipboard(customerData?.account_number)} className="ml-2 cursor-pointer text-blue-500 hover:text-blue-700" />
            </p>
            <p className="mb-2">Account Name: {customerData?.account_name}</p>
            <p>Bank: {customerData?.bank?.name}</p>
          </div>
          <h4 className="text-center text-gray-500 mb-4">Or use this to deposit:</h4>
          <div className="mb-4 p-4 bg-blue-100 text-blue-700 rounded-lg shadow-md">
            <p>You are about to deposit into ({data?.username}) Account</p>
          </div>
          <TextField
            label="Email"
            value={email || ''}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled
          />
          <TextField
            label="Phone"
            value={phone || ''}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled
          />
          <TextField
            label={`NGN ${amount}`}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled
          />
          <PaystackButton
            className="mt-4 w-full bg-blue-900 rounded-lg text-white py-3 hover:bg-blue-800 transition duration-300"
            {...componentProps}
          />
          <div className="text-blue-900 text-center mt-6">
            This page is provided by Softnixx
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/home")}
            className="w-full mt-4"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};
