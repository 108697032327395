import React from "react";
import { FaRocket } from "react-icons/fa";
import { motion } from "framer-motion"; // For animations
import { useNavigate } from "react-router-dom";

export const Developer = () => {
    const navigate = useNavigate()
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center p-6 bg-white shadow-lg rounded-lg w-full max-w-md">
                <motion.div
                    className="mb-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <FaRocket className="text-6xl text-blue-500 mx-auto" />
                </motion.div>
                <h1 className="text-3xl font-bold text-gray-900 mb-2">Coming Soon</h1>
                <p className="text-lg text-gray-700 mb-4">
                    Our API integration is on its way! Stay tuned for more updates.
                </p>
                <p className="text-gray-600">
                    Want to  be the first to know when it's ready?{" "}
                    <button onClick={()=>navigate('/')} className="text-blue-500 hover:underline">
                        an email will be sent
                    </button>.
                </p>
            </div>
        </div>
    );
};
