import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Input.css"; // Import the CSS file for styling

export const Input = ({ label, value, placeholder, disabled, handleChange, encrpt, type}) => {

 
   
  return (
    <div>
      {label && <label className="input-label">{label}</label>}
      <input
        className="input-field"
        value={value}
        type={encrpt||"text"}
        secureentry={encrpt}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange} // No-op function if no onChange handler is provided
      />
    </div>
  );
};

// PropTypes for type-checking
Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

// Default props
Input.defaultProps = {
  label: '',
  value: '',
  placeholder: '',
  disabled: false,
};
