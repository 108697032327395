
import React from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { FaExplosion } from "react-icons/fa6";

export const Message = () => {
  return (
    <div className="container lg:ml-32 ml-12 p-6 bg-white w-auto text-darkblue shadow-lg rounded-lg">
      <h1 className="text-xl font-mono text-center mb-4">Not availabe on web Please Download App</h1>

      <div className="flex flex-col items-center space-y-4">
        <button className="bg-slate-950 p-4 rounded-full flex items-center justify-center">
          <FaExplosion className="text-2xl text-white" />
        </button>
      </div>
      <marquee>
      </marquee>
    </div>
  );
};


