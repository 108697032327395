import React from "react";
import { Footer } from "../landing";
import { Header } from "../header";
import { Outlet } from "react-router-dom";


export  const LandingPageOutlet =()=>{

    return(
        <div>
            <Header />
            <Outlet />
            <Footer />
        </div>
        


    )

}