import React, { useEffect } from "react";
import { useAlert } from "../../component/alert/useAlert";
import { CircularProgress } from "@mui/material"; // Optional loading spinner

export const Transaction = () => {
  const showMessage = useAlert();

  useEffect(() => {
    showMessage("This page is under development. Check back soon!", "info");
  }, [showMessage]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white">
      <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-center max-w-md mx-auto">
        <h1 className="text-2xl font-bold mb-3">Coming Soon!</h1>
        <p className="text-lg mb-5">
          We're currently working on this page. Please check back soon for updates!
        </p>
        
        {/* Optional Loading Icon */}
        <div className="flex justify-center">
          <CircularProgress size={50} color="inherit" />
        </div>
        
        <div className="mt-5">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition duration-300"
            onClick={() => showMessage("We'll notify you when it's ready!", "info")}
          >
            Notify Me
          </button>
        </div>
      </div>
    </div>
  );
};
