import React, { useState, useEffect } from "react";
import { BsWhatsapp } from "react-icons/bs";
import {  FaHandPointDown, FaHandPointLeft } from "react-icons/fa";

import FadeIn from "./text";


import MyComponentB from "./button";

import Phone from "../../src/mobileshow.JPG"
import phone2 from "../../src/comp.jpg"

import { FaGlobe, FaUserCheck } from "react-icons/fa6";
import {  Fade } from "@mui/material";

export const Footer = () => {
    const handleOpenWhatsApp = () => {
        window.open('https://wa.me/+2349061229992', '_blank');
    }

    return (
        <div className="bg-blue-950 p-3 md:p-3 text-center text-white">
            <h2 className="text-4xl md:text-5xl font-bold mb-4">softnixx 1.0</h2>
            <p className="text-lg mb-4">Contact Us via the following</p>
            <button onClick={handleOpenWhatsApp}> <BsWhatsapp
                className="text-6xl md:text-7xl mx-auto mb-4 text-white shadow-lg" />
            </button>
            <div className="mb-6">
                <h3 className="text-2xl md:text-3xl font-semibold mb-2">Download the App and Enjoy Benefits Now</h3>
                <a href="#download" className="bg-blue-600 text-white text-lg font-semibold py-2 px-6 rounded-lg shadow hover:bg-blue-700 transition duration-300">
                    Download Now
                </a>
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-6 md:gap-12 mt-6">
                <div className="flex flex-col items-center">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">1</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">k</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Daily Transactions</h4>
                </div>

                <div className="flex flex-col items-center">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">10</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">+</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Monthly Giveaways</h4>
                </div>

                <div className="flex flex-col items-center">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">1K</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">+</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Gifts Claimed</h4>
                </div>
            </div>
        </div>
    );
};


export const LandingPage = () => {
    const [openHelp, setOpenHelp] = useState(false)

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
    
        const timer = setTimeout(() => {
            setFadeIn(true);
        }, 300); 

        return () => clearTimeout(timer);
    }, []);
    const handleOpenWhatsApp = () => {
        window.open('https://wa.me/+2349061229992', '_blank');
        setOpenHelp(!openHelp)
    };

    const handeOpenHelp = () => {
        setOpenHelp(!openHelp)
    }



    return (
        <center>
        <main className="container lg:ml-32 p-auto bg-white w-auto text-darkblue shadow-lg overflow-hidden rounded-lg mt-32">
            {/* Other sections remain unchanged */}


<div className="text-gray p-3 m-2 bg-white shadow-lg top-1/2 right-4 fixed">
    <h3>

        <button onClick={!openHelp?handeOpenHelp:handleOpenWhatsApp}>
            {!openHelp? 'Need Help?':"Whatsapp"}
        </button>
        {!openHelp?<FaUserCheck className="text-gray-500 text-3xl" />:<BsWhatsapp className="text-green-500 text-3xl" />}
        
    <p>chat our agent online</p> 
    </h3>
    
</div>
             
            <p className="shadow-sm bg-white text-center p-3 m-3 text-blue-950 text-3xl">

            <Fade in={fadeIn}>
                    <span>
                        Welcome to Softnixx Platform, Home of utilities <b>Airtime, Data, Electricity etc..</b> management.
                    </span>
                </Fade>
                </p> 
                <FadeIn>
                    <div className="p-3 m-3 rounded-lg shadow-lg">
                        <img className="animate-pulse" src={phone2} width={300} height={300} alt={" "} />
                    <h2 className="text-center text-2xl p-3 m-3">HOW WE OPERATE</h2>
                <p className="p-3 m-3 text-sm text-justify text-gray-950">Softnixx functions like a combination of a local and a medium-sized bank, allowing you to manage all your utilities and withdraw money within minutes. Our platform offers a clean user interface for easy interactions and ensures lightning-fast communication with our API for an optimal user experience.

Use the register button bellow to get started.</p>
</div>
                </FadeIn>
            
<center>
             <FadeIn>
                <h2 className="text-center p-3 m-2 text-blue-950 text-2xl">
                To get Started Create account or Login
                </h2>
                <FaHandPointDown className="animate-bounce text-2xl text-blue-500" />
            <MyComponentB size={"large"}  variant="variant" type={"submit"} direction="/login" text={"click here"} />

             </FadeIn>
   </center>
   <FadeIn>
    <div className="flex flex-col md:flex-row items-center justify-center w-screen">
        <div>
            <img  src={Phone} width={300} height={300} alt={" "} className="shadow-lg p-3 m-4 animate-pulse rounded-lg" />
        </div>
        <div className="shadow-lg p-3 m-4 rounded-lg w-full md:w-1/2">
            <h1 className="text-center text-2xl text-blue-950">Get your connections back</h1>
            <FaGlobe className="animate-spin text-green-400 text-5xl text-center" />

            <ul className="text-blue-800 text-left text-2xl m-3 p-4">
                <li className="p-2 m-2 flex flex-row items-center">-- Cheap Data  <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" /> </li>
                <li className="p-2 m-2 flex flex-row items-center">-- Buy airtime <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" /></li>
                <li className="p-2 m-2 flex flex-row items-center">-- Redeem points <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" /></li>
                <li className="p-2 m-2 flex flex-row items-center">-- Pay your Electricity bills <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" /></li>
                <li className="p-2 m-2 flex flex-row items-center">-- Pay for your Schools <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" /></li>
                <li className="p-2 m-2 flex flex-row items-center">-- P2P transactions <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" /></li>
            </ul>
            <span>
                Suitable for merchants and consumers. Track your sales and easily share your receipt within a minute.
            </span>
        </div>
    </div>
</FadeIn>


            <FadeIn>
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center"> Utilities Management</h2>
           <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify">Our mission is to reach out to our customers by providing an instant and reliable utilities management platform.</p>

            </FadeIn>

            <FadeIn>
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center"> Cash Gift</h2>
           <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify">
                      
We have created a function where you can manage and receive extra gifts, and potentially earn more on the app. Terms and conditions apply, read more here.
            </p>
            </FadeIn>

            <FadeIn>
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center">  Management of Funds</h2>
           <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify">
                      
<ul>
    <li>-- Transfer to other banks (On hold)
        </li>
        <li>
-- Transfer to Softnixx account
</li>
<li>
-- Deposit money into your account</li>
    </ul>
            </p>

            </FadeIn>



            <FadeIn>
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center">How It Works 1</h2>
           <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify">
                      
<ul>
    <li>--  Fund your wallet
        </li>
        <li>
-- Pay for your utilities
</li>
<li>-- Get receipts</li>
<li>
-- Only successful transactions will be debited for your utilities payment; otherwise, you need to try again.</li>
    </ul>
            </p>

            </FadeIn>

            <FadeIn>
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center">How It Works 2</h2>
           <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify">
                      
<ul>
        <li>
-- We have an inbuilt function to help you make sales in your respective market structure, whether medium or small-scale business.
</li>
<li>-- Click on the plus sign</li>
<li>
-- Upload your product with the specified guidelines</li>
<li>
-- Wait for customers to contact you.
</li>

    </ul>
            </p>

            </FadeIn>


            <FadeIn>
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center">How It Works 3</h2>
           <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify">
                      
<ul>
        <li>
-- P2P Transactions
</li>
<li>-- Offers for Airtime Recharge and Data</li>
<li>
-- Click on the P2P</li>
<li>
-- Upload your ADS with the specified guidelines.
</li>
<li>-- Wait for customers to contact you.</li>

    </ul>
            </p>

            </FadeIn>
        </main>
        </center>

    );
};

