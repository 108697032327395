import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MyComponentB = ({type, variant, size, text, direction}) => {
          const navigate = useNavigate()
    return (
        <div className="text-center">
            <Button
            onClick={()=>navigate(direction)}
                type={type}
                variant={variant} 
                className=" text-white text-3xl px-4 py-2" // Custom classes
                size={size} >
              {text}

            </Button>
        </div>
    );
};

export default MyComponentB;
