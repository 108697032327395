import React, { useState } from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { FaExplosion } from "react-icons/fa6";
import ModalSettings from "./modals";

export const Settings = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  return (
    <div className="">
      <div className="shadow-lg rounded-lg p-8 w-full max-w-md text-center ">
        <div className=" bg-gray-500 p-3 m-2 rounded-lg"><h1 className="text-sm text-white font-bold p-3  text-darkblue mb-6">More Settings</h1>
        <p className="text-sm text-white font-bold p-3 ">
          do you need more setting download our app search softnixx on Appstore or Playstore
        </p>
        </div>
        

        
        <div className="flex flex-col items-center space-y-6 mb-6">
          <button
            onClick={() => window.open("https://www.softnixx.com/api/password_reset/")}
            className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
          >
            Change Password
          </button>
          <button
            onClick={handleOpen}
            className="bg-green-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
          >
            Change Transaction PIN
          </button>
        </div>

    
        <form  className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            New Phone Number:
          </label>
          <input
            type="tel"
           
    
            className="w-full p-2 border rounded-lg mb-3 focus:ring focus:ring-blue-500"
            placeholder="Enter new phone number"
          />
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Update Phone Number
          </button>
        </form>
      </div>
    

    <center>
    <h3 className="text-white bg-gray-500 p-3 m-3">Do you need help? text us here</h3>
    </center>


      <div className="flex justify-center space-x-6">

          <a
            href="mailto:samuelyyyy257@gmail.com"
            className="bg-gray-800 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out"
          >
            <FaMailBulk className="text-2xl" />
          </a>
          <a
            href="https://wa.me/1234567890" // Replace with actual phone number
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-600 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
          >
            <FaWhatsapp className="text-2xl" />
          </a>
          <a
            href="#"
            className="bg-purple-600 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-purple-700 transition duration-300 ease-in-out"
          >
            <FaExplosion className="text-2xl" />
          </a>
        </div>


      <ModalSettings open={modalOpen} onClose={handleClose} />
    </div>
  );
};
